/* Tema de angular material */

@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* Iconos de angular material */

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/* Elimina margen por defecto */

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

/* Pantalla de contenido dentro de menú */
.pantalla-principal {
  /* Tamaño de pantalla 100% de uso*/
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;

  /* Imagen de fondo */
  background-image: url(/assets/img/sarcan-bg-gris.jpg);

  /* Centra imagen y la escala */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  flex: 1;
  display: flex;
  overflow: auto;
}

.box {
  display: flex;
  min-height: min-content;
  flex-direction: column;
}

/* Componentes de menu de ayuda */
.mat-card-titulo {
  margin-bottom: 0.5px;
  /* flex: 0 1 auto;*/
}

/* Divisor de secciones*/
.mat-card-text {
  margin: 1em;
  width: 85%;
  line-height: 150%;
}

/* Imagenes entre secciones */
.mat-card-img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 1px solid rgb(189, 189, 189);
}

/* Subtitulos de cards */
.mat-card-subtitle-text {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

/* Tabla */
.mat-header-cell-text {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.mat-table {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

th.mat-header-cell {
  text-align: center !important;
}

td.mat-cell {
  /* row padding */
  padding-left: 0.5em;
  padding-right: 05em;

  /* row border */
  border-bottom: 1px solid gray;
}

.titulo {
  margin-top: 1em;
  margin-left: 1em;
}

/* .titulo-menu {
  margin-left: 1em;
  margin-right: 1em;
} */

.sidenav-container {
  height: 100%;
}

.sidenav .mat-toolbar {
  background: inherit;
}

.mat-toolbar .mat-primary {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mat-nav-list a.active {
  background: skyblue;
}

.footer {
  opacity: 0.5;
}

.footer section {
  margin: 5px;
}

.espaceador {
  flex: 1 1 auto;
}
